<template>
  <div>
    <b-row>
      <b-col md="3">
        <b-form-group>
          <label>Mã giao dịch</label>
          <b-form-tags v-model="filter.code" input-id="tags-separators" separator=" ,;" placeholder="Nhập mã giao dịch cách nhau bởi dấu ,; và dấu cách"
          ></b-form-tags>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <label>Mã giao dịch trừ tiền</label>
          <b-form-tags v-model="filter.action_info.code" input-id="tags-separators" separator=" ,;" placeholder="Nhập mã thanh toán cách nhau bởi dấu ,; và dấu cách"
          ></b-form-tags>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group>
          <label>Loại giao dịch</label>
          <b-form-select v-model="filter.action_info.type" :options="type"></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group>
          <label>Mã đơn hàng</label>
          <b-form-tags v-model="filter.order_info.code" input-id="tags-separators" separator=" ,;" placeholder="Nhập mã đơn hàng cách nhau bởi dấu ,; và dấu cách"
          ></b-form-tags>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group>
          <label>Phương thức thanh toán</label>
          <b-form-select v-model="filter.receiver_type" :options="paymentMethod"></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group>
          <label>Trạng thái hóa đơn</label>
          <b-form-select v-model="filter.order_info.status" :options="orderStatus"></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="2">
        <b-form-group>
          <label>Trạng thái thanh toán</label>
          <b-form-select v-model="filter.status" :options="transactionStatus"></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <label>Từ ngày đến ngày <a href="javascript:void(0)" @click="showModal()">({{timeSelect.text}})</a></label>
        <b-row>
          <b-col md="6">
            <date-picker :id="`type-date-from`" format="DD-MM-YYYY" v-model="dateFrom"></date-picker>
          </b-col>
          <b-col md="6">
            <date-picker :id="`type-date-to`" format="DD-MM-YYYY" v-model="dateTo"></date-picker>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row v-if="advanced === true">
      <b-col md="3">
        <b-form-group>
          <label>SĐT nguồn</label>
          <b-form-input v-model="filter.action_info.maker.name" @keypress="isNumber($event)"></b-form-input>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group>
          <label>SĐT đích</label>
          <b-form-input v-model="filter.action_info.taker.name" @keypress="isNumber($event)"></b-form-input>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group>
          <label>Nội dung giao dịch</label>
          <b-form-input v-model="filter.title"></b-form-input>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group>
          <label>Trạng thái cộng/trừ Ví</label>
          <b-form-select v-model="filter.action_info.status" :options="actionStatus"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="text-right mb-5">
      <a href="javascript:void(0)" @click="toggleAdvanced()"><span>({{plusOrMinus}})</span> Tìm kiếm nâng cao</a>
    </div>

    <div class="text-right">
      <b-button class="btn-primary mr-2" variant="primary" @click="search"><i class="la la-search"></i> Tìm kiếm</b-button>
      <b-button class="btn-success" variant="success" @click="exportExcel"><i class="la la-download"></i> Xuất excel</b-button>
    </div>

    <b-modal id="time-by" title="Điều kiện tìm kiếm">
      <br />
      <div class="d-block text-center">
        <ul class="list-group">
          <li class="list-group-item" :key="key" v-for="(val, key) in time">
            <a href="javascript:void(0)" @click="selectTime(val)">{{ val.text }}</a>
          </li>
        </ul>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="danger" @click="cancel()"> Hủy </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import Common from "@/core/mixins/common";
import moment from "moment-timezone";
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";

export default {
  name: "FilterForm",
  mixins: [Common],
  components: {
    DatePicker,
  },
  data() {
    return {
      dateFrom: this.getFirstDayOfMonth(),
      dateTo: this.getCurrentDay(),
      filter: {
        code: [],
        receiver_type: "",
        title: "",
        created_at: this.getFirstDayOfMonth() + " 00:00:00" + " - " + this.getCurrentDay() + " 23:59:59",
        completed_at: "",
        order_info: {
          code: [],
          status: "",
        },
        action_info: {
          code: [],
          type: "",
          status: "",
          maker: {
            name: ""
          },
          taker: {
            name: ""
          },
          updated_at: ""
        }
      },
      time: [
        { value: "Completed", text: "Thời gian hoàn thành" },
        { value: "Created", text: "Thời gian tạo" },
        { value: "Action", text: "Thời gian trừ tiền" },
      ],
      timeSelect: { value: "Created", text: "Thời gian tạo" },
      type: [
        { value: "", text: "Tất cả" },
        { value: 1, text: "Nạp tiền" },
        { value: 2, text: "Chuyển tiền" },
        { value: 3, text: "Rút tiền" },
        { value: 4, text: "Thanh toán ví" },
        { value: 5, text: "Giao dịch đảo" },
        { value: 6, text: "Nạp tiền qua chuyển khoản" },
        { value: 8, text: "Hoàn tiền 1 phần" },
        { value: 9, text: "Ưu đãi hoàn tiền" },
        { value: 10, text: "Chuyển tiền lì xì" },
        { value: 11, text: "Chuyển tiền ngân hàng" },
        { value: 12, text: "Donate" },
        { value: 13, text: "Tiền thưởng giới thiệu" },
        { value: 15, text: "Giao dịch bù ví" },
        { value: 16, text: "Chi hộ" },
        { value: 19, text: "Nạp tiền chuyển khoản" },
      ],
      actionStatus: [
        { value: "", text: "Tất cả" },
        { value: 0, text: "Đang chờ" },
        { value: 1, text: "Thành công" },
        { value: -1, text: "Thất bại" },
        { value: 2, text: "Đã hoàn tiền" },
        { value: -2, text: "Hủy" },
      ],
      paymentMethod: [
        { value: "", text: "Tất cả" },
        { value: "9PAY", text: "Ví" },
        { value: "ATM_CARD", text: "Thẻ nội địa qua cổng" },
        { value: "CREDIT_CARD", text: "Thẻ quốc tế qua Cổng" },
      ],
      orderStatus: [
        { value: "", text: "Tất cả" },
        { value: "CREATE", text: "Khởi tạo" },
        { value: "CREATED", text: "Đã khởi tạo" },
        { value: "SUCCESS", text: "Thành công" },
        { value: "FAIL", text: "Thất bại" },
        { value: "PAYMENT_CANCEL", text: "Hủy thanh toán" },
        { value: "PAYMENT_PENDING", text: "Chờ thanh toán" },
        { value: "PAYMENT_SUCCESS", text: "Thanh toán thành công" },
        { value: "EXPIRED_SESSION", text: "Hết hạn" },
        { value: "PROCESSING", text: "Đang xử lý" },
        { value: "WAIT_TO_REVIEW", text: "Chờ phê duyệt" },
        { value: "REJECT", text: "Từ chối" },
        { value: "CANCEL", text: "Hủy" },
      ],
      transactionStatus: [
        { value: "", text: "Tất cả" },
        { value: "PENDING", text: "Đang chờ" },
        { value: "SUCCESS", text: "Thành công" },
        { value: "CREATE", text: "Khởi tạo" },
        { value: "CREATE_ERROR", text: "Khởi tạo thất bại" },
        { value: "CREATE_REPLACE", text: "Giao dịch thay thế" },
        { value: "CREATE_SUCCESS", text: "Khởi tạo thành công" },
        { value: "CREATED", text: "Đã khởi tạo" },
        { value: "FAIL", text: "Thất bại" },
        { value: "CANCEL", text: "Hủy" },
        { value: "PROCESSING", text: "Đang xử lý" },
        { value: "REFUND", text: "Hoàn tiền" },
        { value: "REVERSED", text: "Giao dịch đảo" },
      ],
      advanced: false,
    };
  },
  computed: {
    plusOrMinus() {
      return this.advanced ? "-" : "+";
    },
  },
  watch: {
    dateFrom(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }

      this.selectTime(this.timeSelect);
    },
    dateTo(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }

      this.selectTime(this.timeSelect);
    },
  },
  methods: {
    search() {
      if (this.dateFrom.length <= 0 || this.dateTo.length <= 0) {
        this.notifyAlert("warn", "Vui lòng chọn ngày bắt đầu và kết thúc");
        return;
      }

      if (this.dateFrom > this.dateTo) {
        this.notifyAlert("warn", "Ngày bắt đầu không được lớn hơn ngày kết thúc");
        return;
      }

      this.$emit("search", this.filter);
    },
    exportExcel() {
      if (this.dateFrom > this.dateTo) {
        this.notifyAlert("warn", "Ngày bắt đầu không được lớn hơn ngày kết thúc");
        return;
      }

      this.$emit("exportExcel", this.filter);
    },
    toggleAdvanced() {
      if (this.advanced === true) {
        this.advanced = false;
        return;
      }

      this.advanced = true;
    },
    showModal()
    {
      this.$bvModal.show("time-by");
    },
    cancel() {
      this.$bvModal.hide("time-by");
    },
    selectTime(val) {
      this.timeSelect = val;
      this.$bvModal.hide("time-by");

      this.filter.created_at = "";
      this.filter.completed_at = "";
      this.filter.action_info.updated_a = "";

      let dateFrom = moment(String(this.dateFrom)).format("DD-MM-YYYY") + " 00:00:00";
      let dateTo = moment(String(this.dateTo)).format("DD-MM-YYYY") + " 23:59:59";

      // let dateFrom = this.dateFrom + " 00:00:00";
      // let dateTo = this.dateTo + " 23:59:59";

      this.filter.action_info.updated_at = "";
      this.filter.created_at = "";
      this.filter.completed_at = "";

      if (val.value === "Action") {
        this.filter.action_info.updated_at = dateFrom + " - " + dateTo;
        return;
      }

      if (val.value === "Created") {
        this.filter.created_at = dateFrom + " - " + dateTo;
        return;
      }

      this.filter.completed_at = dateFrom + " - " + dateTo;
    },
  }
}
</script>



<style scoped>

</style>