<template>
  <div>
    <div class="text-right">
      <a href="javascript:void(0)" @click="showModal"><i class="la la-eye"></i> Hiển thị nâng cao</a>
    </div>

    <b-modal size="lg" id="optimize-columns" title="Hiển thị nâng cao">
      <br />
      <div class="d-block p-1">
        <ul class="text-decoration-none list-unstyled row">
          <li class="p-2 border-dark border-solid border-1 col-4" :key="key" v-for="(val, key) in columns">
            <label class=""><input type="checkbox" v-model="optimizeColumns" :value="val" />{{ val.label }}</label>
          </li>
        </ul>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="primary" @click="updateColumns()">Xác nhận</b-button>
        <b-button size="sm" variant="danger" @click="cancel()"> Hủy </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Common from "@/core/mixins/common";

export default {
  name: "OptimizeColumns",
  mixins: [Common],
  props: ["listChoose"],
  data() {
    return {
      optimizeColumns: [],
      columns: [
        { key: "wallet_transaction_id", label: "Mã giao dịch trừ tiền" },
        { key: "code", label: "Mã giao dịch" },
        { key: "order_info.code", label: "Mã đơn hàng" },
        { key: "bank_code", label: "Mã ngân hàng" },
        { key: "order_info.merchant_code", label: "Mã đối tác" },
        { key: "coupon_code", label: "Mã voucher" },
        { key: "user_phone", label: "SĐT" },
        { key: "action_info.account.balance_type", label: "Loại ví" },
        { key: "description", label: "Nội dung giao dịch" },
        { key: "price", label: "Giá trị GD gốc" },
        { key: "fee", label: "Phí giao dịch" },
        { key: "order_info.discount", label: "Chiết khấu" },
        { key: "order_info.voucher", label: "Giá trị voucher" },
        { key: "action_info.amount", label: "Giá trị cộng/ trừ Ví" },
        { key: "action_info.maker_account", label: "Tài khoản nguồn" },
        { key: "action_info.taker_account", label: "Tài khoản đích" },
        { key: "type_link", label: "Phương thức thanh toán" },
        { key: "action", label: "Loại giao dịch" },
        { key: "order_info.product_id", label: "Sản phẩm" },
        { key: "order_info.action", label: "Dịch vụ" },
        { key: "status", label: "Trạng thái giao dịch" },
        { key: "action_info.status", label: "Trạng thái cộng/trừ Ví" },
        { key: "order_info.status", label: "Trạng thái đơn hàng" },
        { key: "created_at", label: "Ngày tạo GD" },
        { key: "updated_at", label: "Ngày cập nhật GD" },
      ],
    };
  },
  mounted() {
    this.optimizeColumns = this.listChoose;
  },
  methods: {
    showModal() {
      this.$bvModal.show("optimize-columns");
    },
    cancel() {
      this.$bvModal.hide("optimize-columns");
    },
    updateColumns() {
      if (this.optimizeColumns.length <= 2) {
        this.notifyAlert("warn", "Bạn không thể bỏ thêm nữa");
        return;
      }

      this.$bvModal.hide("optimize-columns");
      this.$emit("updateColumns", this.optimizeColumns);
    },
  }
}
</script>

<style scoped>

</style>
