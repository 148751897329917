<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Lịch sử giao dịch V3</h6>
              </template>

              <filter-form
                @search="getList"
                @exportExcel="exportData"
              ></filter-form>

              <br />
              <b-row>
                <b-col class="text-left">
                  <b>Tổng số lượng GD: </b> {{ numberFormat(this.total) }} giao
                  dịch <br />
                  <b>Tổng giá trị thanh toán: </b>
                  {{ numberFormat(this.totalAmount) }} VNĐ
                </b-col>
                <b-col>
                  <optimize-columns
                    :listChoose="show_filter"
                    @updateColumns="updateColumns"
                  ></optimize-columns>
                </b-col>
              </b-row>

              <div class="table-responsive">
                <b-table
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="items"
                  :fields="show_filter"
                >
                  <template #cell(detail)="data">
                    <a :href="`${data.value}`">Xem</a>
                  </template>
                  <template #cell(price)="data">
                    {{ numberFormat(data.item.price) }}
                  </template>
                  <template #cell(wallet_transaction_id)="data">
                    {{data.item.wallet_transaction_id ? data.item.wallet_transaction_id : "--"}}
                  </template>
                  <template #cell(refer_wallet_trans_id)="data">
                    {{data.item.refer_wallet_trans_id ? data.item.refer_wallet_trans_id : "--"}}
                  </template>
                  <template #cell(amount)="data">
                    {{ numberFormat(data.item.amount) }}
                  </template>
                  <template #cell(status)="data">
                    {{ transactionStatus(data.item.status) }}
                  </template>
                  <template #cell(action)="data">
                    {{ actionTransform(data.item.action) }}
                  </template>
                  <template #cell(bank_code)="data">
                    {{ data.item.bank_code ? data.item.bank_code : "--" }}
                  </template>
                  <template #cell(coupon_code)="data">
                    {{ data.item.coupon_code ? data.item.coupon_code : "--" }}
                  </template>
                  <template #cell(order_info.status)="data">
                    {{data.item.order_info ? orderStatus(data.item.order_info.status) : "--"}}
                  </template>
                  <template #cell(order_info.code)="data">
                    {{data.item.order_info ? data.item.order_info.code : "--"}}
                  </template>
                  <template #cell(order_info.payment_id)="data">
                    {{data.item.payment_id ? data.item.order_info.payment_id : "--"}}
                  </template>
                  <template #cell(order_info.title)="data">
                    {{data.item.order_info ? data.item.order_info.title : "--"}}
                  </template>
                  <template #cell(order_info.description)="data">
                    {{data.item.order_info ? data.item.order_info.description : "--"}}
                  </template>
                  <template #cell(order_info.merchant_code)="data">
                    {{data.item.order_info ? data.item.order_info.merchant_code : "--"}}
                  </template>
                  <template #cell(order_info.type)="data">
                    {{data.item.order_info ? data.item.order_info.type : "--" }}
                  </template>
                  <template #cell(order_info.action)="data">
                    {{data.item.order_info ? data.item.order_info.action : "--"}}
                  </template>
                  <template #cell(order_info.product_id)="data">
                    {{data.item.order_info && data.item.order_info.product ? data.item.order_info.product.name : "--"}}
                  </template>
                  <template #cell(order_info.fee)="data">
                    {{data.item.order_info ? numberFormat(data.item.order_info.fee) : 0}}
                  </template>
                  <template #cell(order_info.updated_at)="data">
                    {{data.item.order_info ? data.item.order_info.updated_at : "--"}}
                  </template>
                  <template #cell(order_info.created_at)="data">
                    {{data.item.order_info ? data.item.order_info.created_at : "--"}}
                  </template>
                  <template #cell(order_info.voucher)="data">
                    {{data.item.order_info ? numberFormat(data.item.order_info.voucher) : 0}}
                  </template>
                  <template #cell(order_info.amount)="data">
                    {{data.item.order_info ? numberFormat(data.item.order_info.amount) : 0}}
                  </template>
                  <template #cell(order_info.discount)="data">
                    {{data.item.order_info ? numberFormat(data.item.order_info.discount) : 0}}
                  </template>
                  <template #cell(order_info.total)="data">
                    {{data.item.order_info ? numberFormat(data.item.order_info.total) : 0}}
                  </template>
                  <template #cell(action_info.type)="data">
                    {{data.item.action_info ? actionType(data.item.action_info.type) : "--"}}
                  </template>
                  <template #cell(action_info.status)="data">
                    {{data.item.action_info ? actionStatus(data.item.action_info.status) : "--"}}
                  </template>
                  <template #cell(action_info.maker_account)="data">
                    {{data.item.action_info && data.item.action_info.maker ? data.item.action_info.maker.name : "--"}}
                  </template>
                  <template #cell(action_info.taker_account)="data">
                    {{data.item.action_info && data.item.action_info.taker ? data.item.action_info.taker.name : "--"}}
                  </template>
                  <template #cell(action_info.amount)="data">
                    {{data.item.action_info ? numberFormat(data.item.action_info.amount) : 0}}
                  </template>
                  <template #cell(action_info.payment_at)="data">
                    {{data.item.action_info ? data.item.action_info.payment_at : "--"}}
                  </template>
                  <template #cell(action_info.created_at)="data">
                    {{data.item.action_info ? data.item.action_info.created_at : "--"}}
                  </template>
                  <template #cell(action_info.description)="data">
                    {{ data.item.action_info ? data.item.action_info.description : "--" }}
                  </template>
                  <template #cell(coupon.coupon_type)="data">
                    {{ data.item.coupon ? data.item.coupon.coupon_type : "--" }}
                  </template>

                  <template #cell(action_info.account.balance_type)="data">
                    {{ data.item.action_info && data.item.action_info.account ? (data.item.action_info.account.balance_type === 1 ? "Ví thường" : "Ví thưởng") : "--" }}
                  </template>
                </b-table>
              </div>
              <div class="mt-3">
                <b-pagination-nav
                  v-model="currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
    <export-modal :modalId="exportId" @exportData="handleExport"></export-modal>

    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import FilterForm from "@/view/pages/transaction/v3/components/FilterForm.vue";
import OptimizeColumns from "@/view/pages/transaction/v3/components/OptimizeColumns.vue";
import Common from "@/core/mixins/common";
import ExportModal from "@/view/pages/components/ExportModal.vue";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";

const TransactionRepository = RepositoryFactory.get("transactions");
const CmsRepository = RepositoryFactory.get("cms");
export default {
  mixins: [Common],
  components: { AlertDialogue, ExportModal, FilterForm, OptimizeColumns },
  data() {
    return {
      exportId: "export-trans-modal",
      show: false,
      totalPrice: 0,
      totalAmount: 0,
      total: 0,
      show_filter: [
        { key: "wallet_transaction_id", label: "Mã giao dịch trừ tiền" },
        { key: "code", label: "Mã giao dịch" },
        { key: "order_info.code", label: "Mã đơn hàng" },
        { key: "user_phone", label: "SĐT" },
        { key: "action_info.account.balance_type", label: "Loại ví" },
        { key: "description", label: "Nội dung giao dịch" },
        { key: "price", label: "Giá trị GD gốc" },
        { key: "fee", label: "Phí giao dịch" },
        { key: "order_info.discount", label: "Chiết khấu" },
        { key: "order_info.voucher", label: "Giá trị voucher" },
        { key: "action_info.amount", label: "Giá trị cộng/ trừ Ví" },
        { key: "action_info.maker_account", label: "Tài khoản nguồn" },
        { key: "action_info.taker_account", label: "Tài khoản đích" },
        { key: "type_link", label: "Phương thức thanh toán" },
        { key: "action", label: "Loại giao dịch" },
        { key: "status", label: "Trạng thái giao dịch" },
        { key: "action_info.status", label: "Trạng thái cộng/trừ Ví" },
        { key: "order_info.status", label: "Trạng thái đơn hàng" },
        { key: "created_at", label: "Ngày tạo GD" },
        { key: "updated_at", label: "Ngày cập nhật GD" },
      ],
      exportFilter: {},
      items: [],
      currentPage: 1,
      input: {},
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  created() {
    this.getCookie();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Lịch sử giao dịch V3", route: "elastic" },
    ]);
  },
  methods: {
    getCookie() {
      let columns = this.$cookies.get("optimize_columns_cookies");

      if (columns) {
        this.show_filter = columns;
      }
    },
    async getList(params) {
      try {
        this.$bus.$emit("show-loading", true);
        this.exportFilter = params;

        let numberPage = 1;

        if (typeof this.$route.query.page !== "undefined") {
          numberPage = this.$route.query.page;
        }

        params.page = numberPage;
        params = this.convert(params);

        let response = await TransactionRepository.getListElastic(params);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data.data) {
          body.data.data.forEach((value) => {
            value["detail"] = "/#/transactions/detail?transaction_id=" + value.code;
          });
          this.items = body.data.data;
          this.paginate.total = body.data.total;
          this.paginate.totalPage = body.data.last_page;
          this.totalAmount = body.data.aggregations.trans_amount.value;
          this.total = body.data.total;
          this.totalPrice = body.data.totalPrice;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async exportData(filter) {
      this.exportFilter = filter;
      this.$bvModal.show(this.exportId);
    },
    async handleExport(description = null) {
      let params = this.exportFilter;
      params = this.convert(params);
      params.wallet_type = "TRANSACTION_ELASTIC";
      params.export_desc = description;
      params.columns = this.show_filter;
      this.$bvModal.hide(this.exportId);
      this.$bus.$emit("show-loading", true);

      let self = this;

      CmsRepository.exportFileReconcileWallet(params)
        .then(async (response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            self.$bus.$emit("show-loading", false);
            return;
          }

          self.$bus.$emit("show-loading", false);
          let link = window.location.origin + "/#/reconcile/export-data";
          let cfm = await this.$refs.confirmDialogue.show({
            title: "Download File",
            message: "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" + link + "' target='_blank'>TẠI ĐÂY</a>",
            okButton: "Ok",
          });

          if (!cfm) {
            self.$bus.$emit("show-loading", false);
            return (self.errorMessage = "Có lỗi khi lấy thông tin");
          }

          delete params.wallet_type;
          delete params.export_desc;
          delete params.columns;
        })
        .catch(() => {
          delete params.wallet_type;
          delete params.export_desc;
          delete params.columns;
          this.notifyAlert("warn", "Có lỗi xảy ra");
          self.$bus.$emit("show-loading", false);
        });
    },
    convert(params) {
      Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] == "") {
          delete params[key];
        }
      });
      return params;
    },
    updateColumns(columns) {
      this.show_filter = columns;
      let timeExp = 60 * 60 * 60 * 24 * 30;
      this.$cookies.set("optimize_columns_cookies", this.show_filter, timeExp);
    },
    actionType(type) {
      if (!type) {
        return "--";
      }

      let actionType = [
        { value: 1, text: "Nạp tiền" },
        { value: 2, text: "Chuyển tiền" },
        { value: 3, text: "Rút tiền" },
        { value: 4, text: "Thanh toán ví" },
        { value: 5, text: "Giao dịch đảo" },
        { value: 6, text: "Nạp tiền qua chuyển khoản" },
        { value: 8, text: "Hoàn tiền 1 phần" },
        { value: 9, text: "Ưu đãi hoàn tiền" },
        { value: 10, text: "Chuyển tiền lì xì" },
        { value: 11, text: "Chuyển tiền ngân hàng" },
        { value: 12, text: "Donate" },
        { value: 13, text: "Tiền thưởng giới thiệu" },
        { value: 15, text: "Giao dịch bù ví" },
        { value: 16, text: "Chi hộ" },
        { value: 19, text: "Nạp tiền chuyển khoản" },
      ];

      actionType.forEach(function (item) {
        if (type === item.value) {
          type = item.text;
        }
      });

      return type;
    },
    transactionStatus(status) {
      let transactionStatus = [
        { value: "PENDING", text: "Đang chờ" },
        { value: "SUCCESS", text: "Thành công" },
        { value: "CREATE", text: "Khởi tạo" },
        { value: "CREATE_ERROR", text: "Khởi tạo thất bại" },
        { value: "CREATE_REPLACE", text: "Giao dịch thay thế" },
        { value: "CREATE_SUCCESS", text: "Khởi tạo thành công" },
        { value: "CREATED", text: "Đã khởi tạo" },
        { value: "FAIL", text: "Thất bại" },
        { value: "CANCEL", text: "Hủy" },
        { value: "PROCESSING", text: "Đang xử lý" },
        { value: "REFUND", text: "Hoàn tiền" },
        { value: "REVERSED", text: "Giao dịch đảo" },
      ];

      transactionStatus.forEach(function (item) {
        if (status === item.value) {
          status = item.text;
        }
      });

      return status;
    },
    orderStatus(status) {
      let orderStatus = [
        { value: "CREATE", text: "Khởi tạo" },
        { value: "CREATED", text: "Đã khởi tạo" },
        { value: "SUCCESS", text: "Thành công" },
        { value: "FAIL", text: "Thất bại" },
        { value: "PAYMENT_CANCEL", text: "Hủy thanh toán" },
        { value: "PAYMENT_PENDING", text: "Chờ thanh toán" },
        { value: "PAYMENT_SUCCESS", text: "Thanh toán thành công" },
        { value: "EXPIRED_SESSION", text: "Hết hạn" },
        { value: "PROCESSING", text: "Đang xử lý" },
        { value: "WAIT_TO_REVIEW", text: "Chờ phê duyệt" },
        { value: "REJECT", text: "Từ chối" },
        { value: "CANCEL", text: "Hủy" },
      ];

      orderStatus.forEach(function (item) {
        if (status === item.value) {
          status = item.text;
        }
      });

      return status;
    },
    actionStatus(status) {
      let actionStatus = [
        { value: 0, text: "Đang chờ" },
        { value: 1, text: "Thành công" },
        { value: -1, text: "Thất bại" },
        { value: 2, text: "Đã hoàn tiền" },
        { value: -2, text: "Hủy" },
      ];

      actionStatus.forEach(function (item) {
        if (status === item.value) {
          status = item.text;
        }
      });

      return status;
    },
    actionTransform(action) {
      let actions = [
        { value: "DEPOSIT", text: "Nạp tiền" },
        { value: "PAYMENT", text: "Thanh toán" },
        { value: "WITHDRAW", text: "Rút tền" },
        { value: "TRANSFER", text: "Chuyển tiền" },
      ];

      actions.forEach(function (item) {
        if (action === item.value) {
          action = item.text;
        }
      });

      return action;
    },
  },
  watch: {
    currentPage() {
      this.getList(this.exportFilter);
    },
  },
}
</script>
